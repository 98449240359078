import { UserInfoService } from 'src/app/services/user-info.service';
import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage'
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UserDeleted } from 'src/app/interfaces/user-deleted';

@Component({
  selector: 'app-deleteduser',
  templateUrl: './deleteduser.component.html',
  styleUrls: ['./deleteduser.component.css']
})
export class DeleteduserComponent implements OnInit {

  userData: UserDeleted[];


  constructor(public userInfoService: UserInfoService) { }

   ngOnInit(): void {
     this.getUserData()
    
  }

  getUserData() {

    this.userInfoService.getUserDeleted().subscribe((res) => {
      this.userData = res as Array <UserDeleted>;

    })
    
  }
}
