export class Feedback {
  topicCategory: string = "";
  topicClient: string = "";
  topicDate: Date = new Date();
  topicDetails: string = "";
  topicNotes: string = "";
  topicPositive: boolean = false;
  topicReviewed: boolean = false;
  topicSelected: boolean = false;
  topicSubject: string = "";
  topicRating: number = 0;
  topicImplemented: boolean = false;

constructor(topicCategory: string, topicClient: string, topicDate: Date, topicDetails: string, topicNotes: string,
            topicPositive: boolean, topicReviewed: boolean, topicSelected: boolean, topicSubject: string, topicRating:number, topicImplemented: boolean) {
  this.topicCategory = topicCategory;
  this.topicDetails = topicDetails;
  this.topicNotes = topicNotes;
  this.topicSelected = topicSelected;
  this.topicSubject = topicSubject;
  this.topicPositive = topicPositive;
  this.topicClient = topicClient;
  this.topicDate = topicDate;
  this.topicReviewed = topicReviewed;
  this.topicRating = topicRating;
  this.topicImplemented = topicImplemented;
}
}
