// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase:{
    databaseURL: "https://vara-21be0.firebaseio.com",
    apiKey: "AIzaSyDUvkCGqj8jcdVYujdE_tNkKVHNCBqqv1c",
    authDomain: "varaplatforms.firebaseapp.com",
    projectId: "varaplatforms",
    storageBucket: "varaplatforms.appspot.com",
    messagingSenderId: "889536028896",
    appId: "1:889536028896:web:5807e445bc93d5e8047085",
    measurementId: "G-RZFQFBWN9F"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
