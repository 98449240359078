import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { UserInfo } from 'src/app/interfaces/user-info';
import { FaqServiceService } from 'src/app/services/faq-service.service';
import { IdStorageService } from 'src/app/services/id-storage.service';
import { UserAuthserviceService } from 'src/app/services/user-authservice.service';
import { UserInfoService } from 'src/app/services/user-info.service';

@Component({
  selector: 'app-mini-menu',
  templateUrl: './mini-menu.component.html',
  styleUrls: ['./mini-menu.component.css']
})
export class MiniMenuComponent implements OnInit {
  firstD:number;
  dropdown1 =  document.getElementsByClassName("dropdown-btn");
  isLoggedIn: string;
  userData: UserInfo;

  constructor(public idstorage: IdStorageService,
    private af:AngularFireStorage,
    public userInfo: UserInfoService,
    public idStorage: IdStorageService,
    public userauthService: UserAuthserviceService, public router: Router,
    public faqService:FaqServiceService)
   { }

  ngOnInit(): void {
    if (this.idstorage.getUloggedIn() == "true") {
      this.userauthService.reload_profile()
      this.isAdmin()
      this.isInvestor()
    }
    this.faqService.getCategoriesid()

    this.forDropdown1()
  }

  isInvestor(): boolean {
    if ( this.idstorage.getInvestStatus()== "true") {
      return true
    }else{
    return false
    }
  }

  isAdmin(): boolean {
    if ( this.idstorage.getAdminStatus()== "true") {
      return true
    }else{
    return false
    }
  }

  reload(){
    setTimeout(function(){
    window.location.reload();
    },1000);
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "180px";
    document.getElementById("main").style.marginLeft = "180px";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
    document.body.style.backgroundColor = "white";
  }

  forDropdown1(){
    for (this.firstD = 0; this.firstD <this.dropdown1.length; this.firstD++) {
      this.dropdown1[this.firstD].addEventListener("click", function() {
      this.classList.toggle("active");
      var dropdownContent = this.nextElementSibling;
      if (dropdownContent.style.display === "block") {
      dropdownContent.style.display = "none";
      } else {
      dropdownContent.style.display = "block";
      }
      });
    }
  }
}
