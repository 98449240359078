import { UserInfo } from './../../interfaces/user-info';
import { RideData } from './../../interfaces/ride-data';
import { Component, OnInit } from '@angular/core';
import { RideCapturMasterService } from '../../services/ride-captur-master.service'
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { identifierModuleUrl } from '@angular/compiler';
@Component({
  selector: 'app-ridecapture-master',
  templateUrl: './ridecapture-master.component.html',
  styleUrls: ['./ridecapture-master.component.css']
})
export class RidecaptureMasterComponent implements OnInit {

  allRideCaptureData: RideData[];
  driverID: string;
  email: string;
  rideDate: string;
  infoShown: boolean = false;
  rideInfoShow: boolean = false;


  allUserInfo: UserInfo[];
  ridecapture: RideData;
  userInfo: UserInfo[];
  closeResult: string;

  constructor(public rdm: RideCapturMasterService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getAllUserInfo()
    this.clearScreen()
    this.clearScreenForUserInfo()
  }

  clearScreen() {

    if (this.email == "") {
      this.clearRide()

    } else {

      console.log("working")
    }
  }


  clearScreenForRide() {
    if (this.rideDate == "") {
      this.clearRide()

    } else {
      console.log("working")
    }
  }
  clearScreenForUserInfo() {
    if (this.email == "") {
      this.clearRide()

    } else {
      console.log("working")
    }
  }



  callgetridefun() {
    this.getRideData(this.driverID)
  }
  getAllUserInfo() {
    this.rdm.getAllUserInfo().subscribe(res => {
      this.allUserInfo = res as Array<UserInfo>
    })
  }
  // search() {
  //   if (this.rideDate == "") {
  //     this.ngOnInit();
  //   } else {
  //     this.allRideCaptureData = this.allRideCaptureData.filter(res => {
  //       return res.rideDate.toLowerCase().match(this.rideDate.toLowerCase())
  //     })
  //   }
  // }

  searchUserInfo() {
    this.rdm.getUser(this.email).subscribe(res => {
      this.userInfo = res
    })
    this.infoShown = false
  }



  clearRide() {
    this.infoShown = true
    this.email = ""
    //this.rideDate = ""
    //this.ngOnInit();
    //this.getAllUserInfo();
    this.allRideCaptureData = []
    this.allUserInfo = []
  }

  isShow() {
    this.infoShown = false
  }

  getRideData(userId : string) {
    this.rdm.getRideData(userId).subscribe(res => {
      this.allRideCaptureData = res
      console.log(this.rideInfoShow)
    })
  }

  deleteRide(driverId : string) {
    this.rdm.deleteRecord(driverId)
  }
  editBox(content, ride: RideData) {
    this.ridecapture = ride
    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult =
            `Dismissed ${this.getDismissReason(reason)}`;
          this.getRideData(this.driverID);
        });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }

  update(r_id : string, item : RideData) {
    this.rdm.updateRecord(r_id, item)
  }

  inputFilter() {
    var input, filter, table, tr, td, td1, td2, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[2];
      // td1 = tr[i].getElementsByTagName("td")[1];
      // td2 = tr[i].getElementsByTagName("td")[2];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
          continue
        } else {
          tr[i].style.display = "none";
        }

      }
      if (td1){
        txtValue = td1.textContent || td1.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
          continue
        } else {
          tr[i].style.display = "none";
        }

      }
      if (td2){
        txtValue = td2.textContent || td2.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
          continue
        } else {
          tr[i].style.display = "none";
        }

      }
    }

  }

}
