import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Feedback } from 'src/app/interfaces/feedback';
import { FeedbackService } from '../../services/feedback.service';

@Component({
  selector: 'app-feedback-popup',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  closeResult = '';
  clientEmail = '';
  topic = 'WebSite - ';
  subject = '';
  positive  = false;
  reviewed = false;
  currentRate = 0;
  //addFeedback : Feedback;

  constructor(private modalService: NgbModal, private feedBackService: FeedbackService) { }

  ngOnInit(): void {

  }

  open(content) {
    this.modalService.open(content,
   {ariaLabelledBy: 'modal-basic-title'}).result.then(
     (result) => {
      this.closeResult = `Closed with: ${result}`;
      },
     (reason) => {
      this.closeResult =
         `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  sendFeedBack(){
     var addFeedback = new Feedback(this.topic, this.clientEmail,new Date(),"","",this.positive,this.reviewed,false, this.subject, this.currentRate, false);
      this.feedBackService.createFeedback(addFeedback)
      this.subject = "";
      this.topic = 'WebSite - ';
      this.clientEmail = "";
      this.reviewed = false;
      this.positive = false;
      this.currentRate = 0;
  }

}
