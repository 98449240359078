export class EmailList {
  id: string;
  date: Date;
  clientName: string;
  emailAddress: string;
  emailOK: boolean;
  android: boolean;
  ios: boolean;
  selected: boolean;

  constructor(date: Date, id: string = "", clientName: string, emailAddress: string, emailOK: boolean, android: boolean, ios: boolean, selected : boolean = false) {
    this.date = date;
    this.id = id;
    this.clientName = clientName;
    this.emailAddress = emailAddress;
    this.emailOK = emailOK;
    this.android = android;
    this.ios = ios;
    this.selected = selected;
  }
}
