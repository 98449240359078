import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tech-support',
  templateUrl: './tech-support.component.html',
  styleUrls: ['./tech-support.component.css']
})
export class TechSupportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
