import { templateJitUrl } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IdStorageService } from '../services/id-storage.service';
import { UserAuthserviceService } from '../services/user-authservice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
// const Toast = Swal.mixin({

// })
export class LoginComponent implements OnInit {

  constructor(public idStorage: IdStorageService, public authService: UserAuthserviceService,  public router:Router) {
   }

  ngOnInit(): void {
  }

  reload(){
    setTimeout(function(){
    location.reload();
    },1000);
  }
}
