import { Component, OnInit } from '@angular/core';
import { EmailList } from 'src/app/interfaces/emailList';
import { IdStorageService } from 'src/app/services/id-storage.service';
import { MailingListService } from 'src/app/services/mailing-list.service';
import { EmailComponent } from '../../contact/email/email.component';
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';

@Component({
  selector: 'app-email-utility',
  templateUrl: './email-utility.component.html',
  styleUrls: ['./email-utility.component.css']
})
export class EmailUtilityComponent implements OnInit {
  mailingList: EmailList[];
  checkedList : boolean[];
  checked : boolean;
  os: String = "All"
  contact : EmailComponent;
  osList =  ["Android", "iOS", "All"];

  constructor(public idstorage : IdStorageService, public  EmailService : MailingListService) {
    this.contact = new EmailComponent(this.EmailService);
  }

  ngOnInit(): void {
    init("user_2QDWnbR0VGeBJsAsc02i7");
    this.getMailListData('','All');
    this.checked = false;

    this.mailingList.forEach(element =>{
      element.selected = false });
    //this.checkSelected();
  }

  isAnySelected() : boolean {
    var count = 0;
    this.mailingList.forEach(element =>{
      if (element.selected) count++ });
      if (count > 0) return true;
      return false;
  }

  checkSelected(){
    return;
    var send = document.getElementById("SendSelected");
    var remove = document.getElementById("RemoveSelected");

    if (this.isAnySelected()){
      send.className = "disabled";
      remove.className = "disabled";
    } else {
      send.className = "btn-main";
      remove.className = "btn-main";

    }
  }

  removePerson(person : EmailList){
    this.EmailService.remove(person);
    this.EmailService.getMailList("",this.os);
  }
  removeSelected(){
    //if (document.getElementById("RemoveSelected").className="disabled") return;
    if (!this.isAnySelected()) {
      alert("Must select at least one person from the list");
      return;
    }
    alert('Removed all that is/was selected on the mailing list.');
    this.mailingList.forEach(element =>{
     if (element.selected)
      this.EmailService.remove(element);
    });
    this.EmailService.getMailList("",this.os);
  }
  emailSelected(){
    //if (document.getElementById("SendSelected").className="disabled") return;
    if (!this.isAnySelected()) {
      alert("Must select at least one person from the list");
      return;
    }
    alert('Email sent to everyone that is selected on the mailing list.');
    this.mailingList.forEach(element =>{
    if (element.selected)
      this.sendMessage(element) });
  }

  emailAll(){
    alert('Email sent to everyone on the mailing list.');
    this.mailingList.forEach(element =>
    this.sendMessage(element));
  }

  sendMessage(person : EmailList){
    var name = person.clientName;
    var strExtra = ""
    if (person.android && !person.ios) {
      strExtra = "for Android "
    }
    if (!person.android && person.ios) {
      strExtra = "for iOS "
    }
    if (person.android && person.ios) {
      strExtra = "for Android & iOS "
    }
    var message = "Your recieving this message because you requested to be added to our mailing list. We are contacting you now because the application "+ strExtra + "you wanted to be notified about is now available.\nPlease check the website for download links.";
    var email = person.emailAddress;
    var from_name = "Vara Platforms Support";

    var templateParams = {
      to_name: name,
      to_email : email,
      from_name: from_name,
      message: message,
      from_email: email
  };

  emailjs.send('service_phtm1m5', 'template_erp0hop', templateParams)
      .then(function(response) {
         console.log('SUCCESS!', response.status, response.text);
      }, function(error) {
         console.log('FAILED...', error);
      });

  }
  exportList(){
    this.contact.listExport(document);
  }
  isAdmin() : boolean{
    if (this.idstorage.getUloggedIn() == "true" && this.idstorage.getAdminStatus()=="true") return true
    return false
  }
  checkAll(){
     this.mailingList.forEach(element =>{
      if (!element.selected) element.selected = !element.selected
     }
     );
  }
  clearAll(){
     this.mailingList.forEach(element =>{
      element.selected = false;
     }
     );
  }

  changeOS(value : String){
    this.getMailListData('',value);
    this.os = value;
  }

  getMailListData(typed : String = "", filter : String = "") {
    this.EmailService.getMailList(typed, filter).subscribe(
      res => {
        this.mailingList = res as Array<EmailList>
  })
}

inputFilter() {
  var input, filter, table, tr, td, td1, i, txtValue;
  input = document.getElementById("myInput");
  filter = input.value.toUpperCase();
  table = document.getElementById("myTable");
  tr = table.getElementsByTagName("tr");
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[2];
    td1 = tr[i].getElementsByTagName("td")[3];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
        continue
      } else {
        tr[i].style.display = "none";
      }

    }
    if (td1){
      txtValue = td1.textContent || td1.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
        continue
      } else {
        tr[i].style.display = "none";
      }

    }
  }
}
}
