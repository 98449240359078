import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpXhrBackend } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetHTMLService {

  constructor(private http : HttpClient) {
    //this.http = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));

   }

   handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    alert(errorMessage);
    return throwError(errorMessage);
  }

   alertmsg(){
    console.log(this.http.get(window.origin + '/assets/email-contact/contact.html').subscribe(data => {

        alert(data.toString());
      }))
    //.pipe(catchError(this.handleError)))
    alert(this.http.get(window.origin + '/assets/email-contact/contact.html')) //.pipe(catchError(this.handleError)))

   }


}
