import { UserDeleted } from './../interfaces/user-deleted';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Router } from '@angular/router';
// import { UserInfo } from 'node:os';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage'
import { User } from '../interfaces/user';
import { UserInfo } from '../interfaces/user-info';
import { IdStorageService } from './id-storage.service';
import { UserList } from '../interfaces/user-list';
//import { UserAuthserviceService } from './user-authservice.service';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {


  constructor(public db: AngularFirestore,
    //public afAuth: UserAuthserviceService,
    public router: Router, public idStorage: IdStorageService,
    private af: AngularFireStorage) { }

  getUserByID(uid: string): Observable<UserInfo> {
    return this.db.collection('userInfo').doc(this.idStorage.getUId()).snapshotChanges().pipe(
      map(res => {
        const user = res.payload.data() as UserInfo;
        this.idStorage.setAdminStatus(user.admin.toString())
        this.idStorage.setInvestStatus(user.investor.toString())
        return user;
      })
    );
  }



  uploadImg(path: string) {
    this.af.upload("/profile/" + this.idStorage.getUId(), path)
  }



  //user deleted

  // {
  //  return this.db.collection('userDeleted').valueChanges()
  // }

  getUserDeleted(): Observable<UserDeleted[]> {
		return this.db.collection('userDeleted')
			.snapshotChanges().pipe(
				map(action => {
					return action.map(res => {
						const userDeleted = res.payload.doc.data() as UserDeleted;
						const id = res.payload.doc.id;
						return { id, ...userDeleted };
					})
				})
			);
	}

  updateUser(id:string,item:string, value:any){
    return this.db.collection('userInfo').doc(id).update({
        [item]: value
		})
  }


  getAllUserInfo(): Observable<UserInfo[]> {
		return this.db.collection('userInfo')
			.snapshotChanges().pipe(
				map(action => {
					return action.map(res => {
						const ride = res.payload.doc.data() as UserInfo;
						const id = res.payload.doc.id;
						return { id, ...ride };
          })
				})
			);
	}
  getUserList(): Observable<UserList[]> {
		return this.db.collection('userInfo')
			.snapshotChanges().pipe(
				map(action => {
					return action.map(res => {
            const user = res.payload.doc.data() as UserInfo;
            const id = res.payload.doc.id;
						const ride = new UserList(id, (user.firstName + ' ' + user.lastName) , user.employee)
						return { ...ride };
          })
				})
			);
	}

  getFilteredUsers(filter : String = ""): Observable<UserInfo[]> {
    let ref = this.db.collection('userInfo')
    switch (filter){
      case "Investor":{
        ref = this.db.collection('userInfo',reff => reff.where('investor', '==', true));
        break;
      }
      case "Admin" :{
        ref = this.db.collection('userInfo',reff => reff.where('admin', '==', true))
        break;
      }
      case "Employee" :{
        ref = this.db.collection('userInfo',reff => reff.where('employee', '==', true))
        break;
      }
    }

		return ref
			.snapshotChanges().pipe(
				map(action => {
					return action.map(res => {
						const email = res.payload.doc.data() as UserInfo;
						const id = res.payload.doc.id;
            //email.id = id;
						return { id, ...email };
					})
				})
			);
	}

}
