import { Component, OnInit } from '@angular/core';
import { Faq } from 'src/app/interfaces/faq';
import { FaqServiceService } from 'src/app/services/faq-service.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { IdStorageService } from 'src/app/services/id-storage.service';
import { UserInfo } from 'node:os';

@Component({
  selector: 'app-faq-utility',
  templateUrl: './faq-utility.component.html',
  styleUrls: ['./faq-utility.component.css']
})

export class FaqUtilityComponent implements OnInit {

  allGeneralData: Faq[];
  updateItem : Faq;
  question:string;
  answer:string;
  Qnumber:number;
  status:boolean;
  newQuestion:string;
  newAnswer:string;
  newQnumber:number;
  newStatus:boolean;
  newCategory;
  catagory:string;
  allcategory: { active: boolean; category: number; categoryName: string; id: string; }[];
  message: string;
  closeResult: string;
  userType: string;
  userInfoService: any;

 
  editField: string;
  

  constructor(public faqService:FaqServiceService, private modalService: NgbModal, public idstorage:IdStorageService) { }

  ngOnInit(): void {
    this.getFAQDataAll()
    this.getFAqcategories()
  }

  getFAqcategories(){
    this.faqService.getFAQCategories().subscribe(
      res => {
        this.allcategory = res.sort((a,b) => (a as any).category - (b as any).category)
       })
  }
  getFAQDataAll() {
    this.faqService.getAllQuestions().subscribe(
      res => {
        this.allGeneralData = res as Array<Faq>
  })
}
  createRecord(){
  let Record = new Faq(this.newAnswer, this.newCategory,this.newQuestion,this.newStatus,this.newQnumber)

    this.faqService.createNewFAQEntry(Record).then(res => {
      this.newQuestion = ""
      this.newAnswer = ""
      this.newQnumber =undefined
      this.newStatus = false
      this.newCategory = ""
      console.log(res)
      this.message = " faq data saved "
    }).catch(error =>{
      console.log(error)
    })
  }
  deleteRecord(record_id){
    this.faqService.deleteRecord(record_id)
  }
  updateRecord(g_id,item){
    this.faqService.updateRecord(g_id,item)
    this.modalService.dismissAll();
  }

  open(content, item : Faq) {
    this.updateItem = item;
    this.updateItem.category = this.checkTheCategory(item.category);
    this.modalService.open(content,
   {ariaLabelledBy: 'modal-basic-title'}).result.then(
     (result) => {
      this.closeResult = `Closed with: ${result}`;
      },
     (reason) => {
      this.closeResult =
         `Dismissed ${this.getDismissReason(reason)}`;
         this.getFAQDataAll()
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  checkTheCategory(cat:any):string{
    if(cat == this.idstorage.getGeneral()){
      return "General"}
      else if(cat == this.idstorage.getUsage()){
      return "Usage"}
      else if(cat == this.idstorage.getEarn()){
       return "Earn"}
      else if(cat == this.idstorage.getRedeem()){
       return "Redeem"}
       return 'none'
  }


inputFilter() {
  var input, filter, table, tr, td, td1, td2, i, txtValue;
  input = document.getElementById("myInput");
  filter = input.value.toUpperCase();
  table = document.getElementById("table");
  tr = table.getElementsByTagName("tr");
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[3];
    //td1 = tr[i].getElementsByTagName("td")[0];
    // td2 = tr[i].getElementsByTagName("td")[2];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
        continue
      } else {
        tr[i].style.display = "none";
      }

    }
    if (td1){
      txtValue = td1.textContent || td1.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
        continue
      } else {
        tr[i].style.display = "none";
      }
     }
    // if (td2){
    //   txtValue = td2.textContent || td2.innerText;
    //   if (txtValue.toUpperCase().indexOf(filter) > -1) {
    //     tr[i].style.display = "";
    //     continue
    //   } else {
    //     tr[i].style.display = "none";
    //   }

    // }
  }
}

}
