export class Employee {
  UserId: string;
  displayName : string;
  displayStatus : boolean;
  employeeActive : boolean;
  employeeImage : string;
  employeeManager: boolean;
  employeeName : string;
  employeeNumber: number;
  employeeProfile: string;
  employeeTitle: string;
  employeeType: string;
  managerName: string;
  timeApproved: boolean;

  constructor(userID: string, employeeNumber : number, employeeProfile : string, employeeTitle : string,
     employeeType : string, managerName : string, timeApproved, displayName : string, displayStatus : boolean,
     employeeActive: boolean, employeeImage: string, employeeManager : boolean, employeeName : string){
    this.UserId = userID;
    this.employeeNumber = employeeNumber;
    this.employeeProfile = employeeProfile;
    this.employeeTitle = employeeTitle;
    this.employeeType = employeeType;
    this.managerName = managerName;
    this.timeApproved = timeApproved;
    this.displayName = displayName;
    this.displayStatus = displayStatus;
    this.employeeActive = employeeActive;
    this.employeeImage = employeeImage;
    this.employeeManager = employeeManager;
    this.employeeName = employeeName;
  }
}
