/* export.service.ts */
import { Injectable, ElementRef } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExportEmailService {

  constructor() { }

  /**
   * Creates excel from the table element reference.
   *
   * @param element DOM table element reference.
   * @param fileName filename to save as.
   */
  //public exportTableElmToExcel(element: ElementRef, fileName: string): void {
    public exportTableElmToExcel(element: HTMLElement, fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    // generate workbook and add the worksheet
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    workbook.Sheets['Sheet1']['A1'] = 'blank this';
    for(var i = 0;i < 100;i++){
      //var desired_cell = workbook.Sheets['Sheet1']['D'+String(i)];
      //var desired_value = (desired_cell ? desired_cell.v : undefined)
      //if (workbook.Sheets['Sheet1']['D'+String(i)].trim == 'Send')
      //if (desired_cell.v != undefined) alert(desired_cell.v)
      workbook.Sheets['Sheet1']['D'+String(i)] = 'blank this';
    }

    // save to file
    XLSX.writeFile(workbook, `${fileName}${EXCEL_EXTENSION}`);

  }
  //...
}
