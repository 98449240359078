import { RideData } from './../interfaces/ride-data';
import { UserInfo } from './../interfaces/user-info';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RideCapturMasterService {
  rideData:RideData

  constructor(public db:AngularFirestore) { }
  getdata(){
    return this.db.collection('rideCapture').snapshotChanges()
  }

  getAllRideCapture():Observable<RideData[]>{
     return this.db.collection('rideCapture').snapshotChanges()
     .pipe(map(
      action => {
        return action.map(res => {
          const ride = res.payload.doc.data() as RideData;
          const id = res.payload.doc.id;
          return { id, ...ride };
        })
      }
    ))
  }

  deleteRecord(driverId:string) {
		this.db.collection('rideCapture').doc(driverId).delete()
	}

  getAllUserInfo():Observable<UserInfo[]>{
    return this.db.collection('userInfo').snapshotChanges()
    .pipe(map(
     action => {
       return action.map(res => {
         const ride = res.payload.doc.data() as UserInfo;
         const id = res.payload.doc.id;
         return { id, ...ride };
       })
     }
   ))
 }

 getUser(email : string = ""): Observable<UserInfo[]> {
  let ref = this.db.collection('userInfo',reff => reff.where('email', '==', email));

  return ref
    .snapshotChanges().pipe(
      map(action => {
        return action.map(res => {
          const user = res.payload.doc.data() as UserInfo;
          const id = res.payload.doc.id;
          return { id, ...user };
        })
      })
    );
}
getRideData(driverID : string = ""): Observable<RideData[]> {
  let ref = this.db.collection('rideCapture',reff => reff.where('driverID', '==', driverID));

  return ref
    .snapshotChanges().pipe(
      map(action => {
        return action.map(res => {
          const user = res.payload.doc.data() as RideData;
          const id = res.payload.doc.id;
          return { id, ...user };
        })
      })
    );
}

updateRecord(r_id : string, item : RideData) {
  return this.db.collection('rideCapture').doc(r_id).set({
    adsPlayed: item.adsPlayed,
    agreement: item.agreement,
    clientEmail: item.clientEmail,
    deviceName: item.deviceName,
    rideDate: item.rideDate,
    driverID: item.driverID
  })
}
}
