import { Injectable } from '@angular/core';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee } from '../interfaces/employee';
import { IdStorageService } from './id-storage.service';
import Swal from 'sweetalert2';
import { UserInfo } from '../interfaces/user-info';
import { UserList } from '../interfaces/user-list';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  employeesData : Array<Employee>;

  currentID : string;
  currentUserID:string;
  path: string;

  constructor(public db: AngularFirestore,
    public idstorage: IdStorageService,
    //public afAuth: UserAuthserviceService,
    public router: Router,
    private af: AngularFireStorage) {
      //this.db = firebase.database();
     }

  getByUserID(userid: string = "",filter : string = ""): Observable<Employee[]> {
    if (userid == "") {
      var ref = this.db.collection('employees');
      switch(filter){
        case "Active":{
         // var ref = this.db.collection('employees');
         ref = this.db.collection('employees',reff => reff.where('employeeActive', '==', true));
         break;
        }
        case "Inactive":{
          // var ref = this.db.collection('employees');
          ref = this.db.collection('employees',reff => reff.where('employeeActive', '!=', true));
          break;
         }
         case "Contract":{
          // var ref = this.db.collection('employees');
          ref = this.db.collection('employees',reff => reff.where('employeeType', '==', 'CON'));
          break;
         }
         case "Full-Time":{
          // var ref = this.db.collection('employees');
          ref = this.db.collection('employees',reff => reff.where('employeeType', '==', 'FT'));
          break;
         }
         case "Part-Time":{
          // var ref = this.db.collection('employees');
          ref = this.db.collection('employees',reff => reff.where('employeeType', '==', 'PT'));
          break;
         }
      }
    } else {
      var ref = this.db.collection('employees', ref => ref.where('UserId', '==', userid))
    }

    return ref
    .snapshotChanges().pipe(
      map(action => {
        return action.map(res => {
          const email = res.payload.doc.data() as Employee;
          const id = res.payload.doc.id;
          //this.idstorage.setEmployeeID(id);
          //console.log(email);
          return {id,...email };
        })
      })
    );
  }

  getManagerList(): Observable<Employee[]> {

    var ref = this.db.collection('employees',reff => reff.where('employeeManager', '==', true));

    return ref
    .snapshotChanges().pipe(
      map(action => {
        return action.map(res => {
          const email = res.payload.doc.data() as Employee;
          const id = res.payload.doc.id;
          //this.idstorage.setEmployeeID(id);
          //console.log(email);
          return {id,...email };
        })
      })
    );
  }

  setEmployeeID(docID: string){
    this.currentID = docID;
    //alert(this.currentID);
  }

  returnEmployeeCount():number{
    return this.employeesData.length;
  }

  returnEmployeeID(userArray : Array<UserList>, name:string):string{
    for (let i = 0; i < userArray.length; i++) {
      //console.log(userArray[i].userID);
      if (userArray[i].userName == name){
       return userArray[i].userID;
      }
    }
    return "";
  }
  returnEmployeeNumber(employeeArray : Array<Employee>):number{
  for (let i = 0; i < employeeArray.length; i++) {
    console.log(employeeArray[i].UserId);
    if (employeeArray[i].UserId == this.idstorage.getUId()){
     return i;
    }
  }
  return -1;
}

returnEmploymentType(type: string):string{
  if (type=='FT') return 'Full-Time'
  if (type=='PT') return 'Part-Time'
  if (type=='CON') return 'Contract'
  return type;
}
returnTitle(type: string):string{
  if (type=='SVP') return 'Senior VP';
  if (type=='SDEV') return 'Senior Developer';
  return type;
  // if (type=='PT') return 'Part-Time'
  // if (type=='CON') return 'Contract'
}

addEmployee(employee : Employee){
  var employee = new Employee('', 10,'','new','new','none',false,'new exmployee',true,true,'',false,'new employee');
  this.createEmployeeRecord(employee);
  Swal.fire('Created','Employee Data Created','info');
}

removeEmployee(){
  Swal.fire('Remove someone?','Cant yet not implemented.','info');
}

updateEmployee(){
  Swal.fire('Update someone?','Cant yet not implemented.','info');
}


  createEmployeeRecord(employee: Employee){
    return this.db.collection('employees').add({
      UserId: employee.UserId,
      displayName : employee.displayName,
      displayStatus : employee.displayStatus,
      employeeActive : employee.employeeActive,
      employeeImage : employee.employeeImage,
      employeeManager: employee.employeeManager,
      employeeName : employee.employeeName,
      employeeNumber: employee.employeeNumber,
      employeeProfile: employee.employeeProfile,
      employeeTitle: employee.employeeTitle,
      employeeType: employee.employeeType,
      managerName: employee.managerName,
      timeApproved: employee.timeApproved
    })
  }
  updateEmployeeRecord(docID:string = "", employee : Employee){
    if (docID == "") {
      employee.UserId = this.idstorage.getUId();
      this.createEmployeeRecord(employee);
      return
    }
    //docID = this.currentID;
    return this.db.collection('employees').doc(docID).set({
      UserId: employee.UserId,
      displayName : employee.displayName,
      displayStatus : employee.displayStatus,
      employeeActive : employee.employeeActive,
      employeeImage : employee.employeeImage,
      employeeManager: employee.employeeManager,
      employeeName : employee.employeeName,
      employeeNumber: employee.employeeNumber,
      employeeProfile: employee.employeeProfile,
      employeeTitle: employee.employeeTitle,
      employeeType: employee.employeeType,
      managerName: employee.managerName,
      timeApproved: employee.timeApproved
    })
  }

  // upload($event: { target: { files: string[]; }; }) {
  //   this.path = $event.target.files[0]
  // }

  // uploadImg(path: string) {
  //   this.af.upload("/profile/" + this.idstorage.getEmployeeID(), path)
  // }

  uploadEmployeeImg(path: string){
    this.af.upload("/Employee/" + this.idstorage.getUId(), path)
  }

  getEmployeeWithDisplayStatus(): Observable<Employee[]> {
		return this.db.collection('employees', ref => ref.where('displayStatus', '==', true))
			.snapshotChanges().pipe(
				map(action => {
					return action.map(res => {
						const employee = res.payload.doc.data() as Employee;
						const id = res.payload.doc.id;
						return { id, ...employee };
					})
				})
			);
	}
}
