export class UserList {
  userID: string;
  userName:string;
  employee:boolean;

  constructor(userID:string, userName:string, employee:boolean){
    this.userID = userID;
    this.userName = userName;
    this.employee = employee;
  }
}
