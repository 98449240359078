import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generics-coming-soon',
  templateUrl: './generics-coming-soon.component.html',
  styleUrls: ['./generics-coming-soon.component.css']
})
export class GenericsComingSoonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
