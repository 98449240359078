import { ThisReceiver } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Faq } from '../interfaces/faq';
import { FaqCategory } from '../interfaces/faq-category';
import { IdStorageService } from './id-storage.service';

@Injectable({
	providedIn: 'root'
})
export class FaqServiceService {
	generalid: string;
	usageid: string;
	constructor(public db: AngularFirestore, public idstorage: IdStorageService) { }

	getFAQCategories() {
		return this.db.collection('faqCategory', ref => ref.where('active', '==', true))
			.snapshotChanges().pipe(map(action => {
				return action.map(res => {
					const faqC = res.payload.doc.data() as FaqCategory;
					const id = res.payload.doc.id;
					return { id, ...faqC }
				})
			}))
	}

	getCategoriesid() {
		this.getFAQCategories().subscribe(
			res => {
				res.forEach(res => {
					if (res.categoryName === "General") {
						this.idstorage.setGeneral(res.id)
					}
					else if (res.categoryName === "Usage") {
						this.idstorage.setUsage(res.id)
					} else if (res.categoryName === "Earn") {
						this.idstorage.setEarn(res.id)
					} else if (res.categoryName === "Redeem") {
						this.idstorage.setRedeem(res.id)
					}
				})
			})
	}

	getFAQGeneral(): Observable<Faq[]> {
		return this.db.collection('faq', ref => ref.where('category', '==', this.idstorage.getGeneral()
		).where('active', '==', true))
			.snapshotChanges().pipe(
				map(action => {
					return action.map(res => {
						const faqG = res.payload.doc.data() as Faq;
						const id = res.payload.doc.id;
						return { id, ...faqG };
					})
				})
			);
	}

	getFAQUsage(): Observable<Faq[]> {
		return this.db.collection('faq', ref => ref.where('category', '==', this.idstorage.getUsage()
		).where('active', '==', true))
			.snapshotChanges().pipe(
				map(action => {
					return action.map(res => {
						const faqG = res.payload.doc.data() as Faq;
						const id = res.payload.doc.id;
						return { id, ...faqG };
					})
				})
			);
	}
	getFAQEarn(): Observable<Faq[]> {
		return this.db.collection('faq', ref => ref.where('category', '==', this.idstorage.getEarn()
		).where('active', '==', true))
			.snapshotChanges().pipe(
				map(action => {
					return action.map(res => {
						const faqG = res.payload.doc.data() as Faq;
						const id = res.payload.doc.id;
						return { id, ...faqG };
					})
				})
			);
	}

	createNewFAQEntry(Record: Faq) {

		return this.db.collection('faq').doc().set({
			answer: Record.answer,
			category: this.getCategoryString(Record.category),
			question: Record.question,
			active: Record.active,
			sortOrder: Record.sortOrder
		})
	}

	getCategoryString(catName: string): string {
		if (catName == "General") {
			return this.idstorage.getGeneral()
		} else if (catName == "Usage") {
			return this.idstorage.getUsage()
		} else if (catName == "Earn") {
			return this.idstorage.getEarn()
		} else if (catName == "Redeem") {
			return this.idstorage.getRedeem()
		}
		return ""
	}

	getFAQRedeem(): Observable<Faq[]> {
		return this.db.collection('faq', ref => ref.where('category', '==', this.idstorage.getRedeem()
		).where('active', '==', true))
			.snapshotChanges().pipe(
				map(action => {
					return action.map(res => {
						const faqG = res.payload.doc.data() as Faq;
						const id = res.payload.doc.id;
						return { id, ...faqG };
					})
				})
			);
	}

	getAllQuestions() {
		return this.db.collection('faq', ref => ref.orderBy('category'))
			.snapshotChanges().pipe(
				map(action => {
					return action.map(res => {
						const faqG = res.payload.doc.data() as Faq;
						const id = res.payload.doc.id;
						return { id, ...faqG };
					})
				})
			);
	}

	deleteRecord(record_id) {
		this.db.collection('faq').doc(record_id).delete()
	}

	updateRecord(g_id, item) {
		return this.db.collection('faq').doc(g_id).set({
			answer: item.answer,
			category: this.getCategoryString(item.category),
			question: item.question,
			active: item.active,
			sortOrder: item.sortOrder
		})
	}
}
