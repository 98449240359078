import { from } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { AngularFireDatabaseModule } from '@angular/fire/database'
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TeamComponent } from './team/team.component';
import { ContactComponent } from './contact/contact.component';
import { TechSupportComponent } from './tech-support/tech-support.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { environment } from'../environments/environment'
import { NgForm } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { UserAuthserviceService } from './services/user-authservice.service';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PrivacyComponent } from './footer/privacy/privacy.component';
import { InvestorPageComponent } from './investor-page/investor-page.component';
import { RouterModule, Routes } from '@angular/router';
import { ProductComponent } from './product/product.component';
import { PersonalProductComponent } from './product/personal-product/personal-product.component';
import { CommercialProductComponent } from './product/commercial-product/commercial-product.component';
import { FAQComponent } from './tech-support/faq/faq.component';
import { HelpComponent } from './tech-support/help/help.component';
import { TermsComponent } from './footer/terms/terms.component';
import { AdsInfoComponent } from './profile-info/ads-info/ads-info.component';
import {AngularFireStorageModule} from '@angular/fire/storage';
import { PopupComponent } from './contact/popup/popup.component'
import { EmailComponent } from './contact/email/email.component';
import { FeedbackComponent } from './footer/feedback/feedback.component';
import { MiniMenuComponent } from './nav-bar/mini-menu/mini-menu.component'
import { MailingListService } from './services/mailing-list.service';
import { GenericsComingSoonComponent } from './generics-coming-soon/generics-coming-soon.component';
import { AdministrationComponent } from './administration/administration.component';
import { EmailUtilityComponent } from './administration/email-utility/email-utility.component';
import { FaqUtilityComponent } from './administration/faq-utility/faq-utility.component';
import { FeedbackUtilityComponent } from './administration/feedback-utility/feedback-utility.component';
import { HttpClientModule } from '@angular/common/http';
import { RidecaptureMasterComponent } from './administration/ridecapture-master/ridecapture-master.component';
import { DeleteduserComponent } from './administration/deleteduser/deleteduser.component';
import { UserAccessComponent } from './administration/user-access/user-access.component';
import { EmployeeRecordComponent } from './administration/employee-record/employee-record.component';
import { EarningsComponent } from './earnings/earnings.component';

const routes: Routes = [

];
@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    AboutUsComponent,
    TeamComponent,
    ContactComponent,
    TechSupportComponent,
    FooterComponent,
    LoginComponent,
    SignUpComponent,
    ProfileInfoComponent,
    PrivacyComponent,
    InvestorPageComponent,
    ProductComponent,
    PersonalProductComponent,
    CommercialProductComponent,
    FAQComponent,
    HelpComponent,
    TermsComponent,
    AdsInfoComponent,
	  PopupComponent,
    EmailComponent,
    FeedbackComponent,
    MiniMenuComponent,
    GenericsComingSoonComponent,
    AdministrationComponent,
    EmailUtilityComponent,
    FaqUtilityComponent,
    FeedbackUtilityComponent,
    RidecaptureMasterComponent,
    DeleteduserComponent,
    UserAccessComponent,
    EmployeeRecordComponent,
    EarningsComponent,
  ],
  imports: [
    BrowserModule,
    AngularFireStorageModule,
    RouterModule.forRoot(routes, { useHash: true }),
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAk8AwKvL4wJ2JOj2-ctsZS0gSYFg5uvPQ"

    }),
    NgbModule,
  ],
  providers: [UserAuthserviceService, EmailComponent, MailingListService],
  bootstrap: [AppComponent]
})
export class AppModule { }
