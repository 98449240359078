import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-personal-product',
  templateUrl: './personal-product.component.html',
  styleUrls: ['./personal-product.component.css']
})
export class PersonalProductComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
