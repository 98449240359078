import { Component, OnInit } from '@angular/core';
import { IdStorageService } from '../services/id-storage.service';
import { UserInfo } from '../interfaces/user-info';
import { UserInfoService } from '../services/user-info.service';
import { UserAuthserviceService } from '../services/user-authservice.service';
import { RideData } from '../interfaces/ride-data';
import { RideDataService } from '../services/ride-data.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage'
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { EmployeeService } from '../services/employee.service';
import { Employee } from '../interfaces/employee';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.css']
})
export class ProfileInfoComponent implements OnInit {
  Uid: string;
  userData: UserInfo;
  employeesData: Array<Employee>;
  currentUserID : string;
  employeeData: Employee;
  allRideData: RideData[];
  ride: RideData;
  path: string;

  adminStatus: boolean = false;
  imageURL: string = "";
  uid: string = this.idStorage.getUId();
  employeeIndex: number;
  displayEmployeeData = false;

  constructor(public userInfo: UserInfoService,
    public idStorage: IdStorageService,
    private af: AngularFireStorage,
    private afAuth: UserAuthserviceService,
    public router: Router,
    public employeeService : EmployeeService) { }

  ngOnInit(): void {
    this.getUserData();
  }

  getEmail() : string{
    return this.userData.email;
  }

  getUserData() {

    this.userInfo.getUserByID(this.uid).subscribe((res) => {

      this.userData = res as UserInfo;

      //console.log(this.userData.employee, this.uid);
      if (this.userData.employee == true){
        this.employeeService.getByUserID(this.uid).subscribe((res) => {
          // console.log(res);
          this.employeesData = res as unknown as Array<Employee>;
          this.employeeIndex = this.employeeService.returnEmployeeNumber(this.employeesData);
          //this.employeeIndex = 0;
          this.employeeData = this.employeesData[this.employeeIndex];
          this.idStorage.setManager(this.employeeData.employeeManager);
          //console.log(this.employeeIndex);
          // console.log(this.employeeData);
          if (this.employeeIndex != -1) this.displayEmployeeData = true;
        });

      }

    });
  }

  upload($event: { target: { files: string[]; }; }) {
    this.path = $event.target.files[0]
  }

  uploadImg() {
    if (this.path != null) {
      this.userInfo.uploadImg(this.path)
      this.reload()
    } else {
      this.afAuth.toastMessage("Select a photo to upload.")
    }
  }

  uploadEmployeeImg() {
    if (this.path != null) {
      this.employeeService.uploadEmployeeImg(this.path)
      this.reload()
    } else {
      this.afAuth.toastMessage("Select a photo to upload.")
    }
  }

  async editEmployeeProfileName(docID:string){
    this.employeeService.setEmployeeID(docID);
    var displayName: string = this.employeeData.displayName;
    var employeeName: string = this.employeeData.employeeName;
    //this.employeeService.returnEmployeeID(employeeName);
    //var lastName: string = this.userData.lastName;

    const { value: formValues } = await Swal.fire({
      title: 'Edit Name',
      html:
      '<label>Name</label><input id="swal-input1" class="swal2-input" value="' + employeeName + '">' +
      '<label>Display Name</label><input id="swal-input2" class="swal2-input" value="' + displayName + '">' +
      // '<label></label></labal><textarea max="3" id="swal-input1" class="swal2-input" value="' + displayName + '">' +
        '',
        // '<label>Last:</label><input id="swal-input2" class="swal2-input" value="' + lastName + '">',
      confirmButtonColor: "#FF66C4",
      showCancelButton: true,
      focusConfirm: false,
      preConfirm: () => {
        return [
          (<HTMLInputElement>document.getElementById('swal-input1')).value,
          (<HTMLInputElement>document.getElementById('swal-input2')).value
        ]
      }
    })

     if (formValues) {
      this.employeeData.displayName = formValues[1];
      this.employeeData.employeeName = formValues[0];
       this.saveEmployeeProfile(docID);
    }
  }
  async editProfileName() {
    var firstName: string = this.userData.firstName;
    var lastName: string = this.userData.lastName;

    const { value: formValues } = await Swal.fire({
      title: 'Edit Name',
      html:
        '<label>First:</label><input id="swal-input1" class="swal2-input" value="' + firstName + '">' +
        '<label>Last:</label><input id="swal-input2" class="swal2-input" value="' + lastName + '">',
      confirmButtonColor: "#FF66C4",
      showCancelButton: true,
      focusConfirm: false,
      preConfirm: () => {
        return [
          (<HTMLInputElement>document.getElementById('swal-input1')).value,
          (<HTMLInputElement>document.getElementById('swal-input2')).value
        ]
      }
    })

    if (formValues) {
      this.userData.firstName = formValues[0];
      this.userData.lastName = formValues[1];
      this.saveProfile();
    }
  }

  async editProfile(which: string) {
    var text: string;
    var title: string;
    var warning: string = "";
    switch (which) {
      case 'shortName':
        text = this.userData.shortName;
        title = "Display Name"
        break;
      case 'primNum':
        text = this.userData.primaryPhone;
        title = "Cell Phone #"
        break;
      case 'secNum':
        text = this.userData.secondaryPhone;
        title = "Other Phone #"
        break
      case 'email':
        text = this.userData.email;
        title = "Email"
        warning = "Warning: If you change your email address here it will also change your email for login as well."
        break;
      case 'city':
        text = this.userData.city;
        title = "City"
        break;
      case 'company':
        text = this.userData.companyName;
        title = "Company Name"
        break;
      default:
        return
    }

    const { value: formValues } = await Swal.fire({
      title: 'Edit ' + title,
      html:
        '<input id="swal-input1" class="swal2-input" value="' + text + '">',
      confirmButtonColor: "#FF66C4",
      footer: '<h2 class="txt-clr-blue">' + warning + '</h2>',
      showCancelButton: true,
      focusConfirm: false,
      preConfirm: () => {
        return [
          (<HTMLInputElement>document.getElementById('swal-input1')).value
        ]
      }
    })

    if (formValues) {
      switch (which) {
        case "shortName":
          this.userData.shortName = formValues[0];
          break;
        case "primNum":
          this.userData.primaryPhone = formValues[0];
          break;
        case "secNum":
          this.userData.secondaryPhone = formValues[0];
          break
        case "email":
          this.userData.email = formValues[0];
          break;
        case "city":
          this.userData.city = formValues[0];
          break;
        case "company":
          this.userData.companyName = formValues[0];
          break;
        default:
          return
      }
      this.saveProfile();
    }
  }

  saveProfile() {
    let itemRef = this.userInfo.db.collection('userInfo').doc(this.idStorage.getUId()).set(this.userData);
    this.afAuth.toastMessage("Update to profile successful.")
  }

  saveEmployeeProfile(docID:string) {
    this.employeeService.updateEmployeeRecord(docID, this.employeeData);
    //let itemRef = this.userInfo.db.collection('').doc(this.idStorage.getUId()).set(this.userData);
    this.afAuth.toastMessage("Update to profile successful.")
  }

  reload(): void {
    setTimeout(() => { window.location.reload() }, 4000)

  }

}
