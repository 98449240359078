import { GenericsComingSoonComponent } from './generics-coming-soon/generics-coming-soon.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { InvestorPageComponent } from './investor-page/investor-page.component';
import { LoginComponent } from './login/login.component';
import { CommercialProductComponent } from './product/commercial-product/commercial-product.component';
import { PersonalProductComponent } from './product/personal-product/personal-product.component';
import { ProductComponent } from './product/product.component';
import { AdsInfoComponent } from './profile-info/ads-info/ads-info.component';
import { DeleteduserComponent } from './administration/deleteduser/deleteduser.component';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { TeamComponent } from './team/team.component';
import { TechSupportComponent } from './tech-support/tech-support.component';
import { FeedbackUtilityComponent } from './administration/feedback-utility/feedback-utility.component';
import { FaqUtilityComponent } from './administration/faq-utility/faq-utility.component';
import { EmailUtilityComponent } from './administration/email-utility/email-utility.component';
import { AdministrationComponent } from './administration/administration.component';
import {RidecaptureMasterComponent } from './administration/ridecapture-master/ridecapture-master.component'
import { UserAccessComponent } from './administration/user-access/user-access.component';
import { EmployeeRecordComponent } from './administration/employee-record/employee-record.component';
import { EarningsComponent } from './earnings/earnings.component';

const routes: Routes = [

  {
    path: 'earnings',
    component: EarningsComponent
  },
  {
    path: 'team',
    component: TeamComponent
  },
  {
    path: 'ridecapture-master',
    component: RidecaptureMasterComponent
  },

  {
    path: 'coming-soon',
    component: GenericsComingSoonComponent
  },
  {
    path: 'investor-page',
    component: InvestorPageComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'products',
    component: ProductComponent
  },
  {
    path: 'product-commercial',
    component: CommercialProductComponent
  },
  {
    path: 'product-personal',
    component: PersonalProductComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'tech-support',
    component: TechSupportComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'sign-up',
    component: SignUpComponent
  },
  {
    path: 'profile-info',
    component: ProfileInfoComponent
  },
  {
    path: 'ads-info',
    component: AdsInfoComponent
  },
  {
    path: 'deleted-user',
    component: DeleteduserComponent
  },
  {
    path: 'admin',
    component: AdministrationComponent
  },
  {
    path: 'feedback-utility',
    component: FeedbackUtilityComponent
  },
  {
    path: 'faq-utility',
    component: FaqUtilityComponent
  },
  {
    path: 'email-utility',
    component: EmailUtilityComponent
  },
  {
    path: 'user-access',
    component: UserAccessComponent
  },
  {
    path: 'employee-record',
    component: EmployeeRecordComponent
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
