import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserList } from 'src/app/interfaces/user-list';
import { IdStorageService } from 'src/app/services/id-storage.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { Employee } from '../../interfaces/employee';
import { EmployeeService } from '../../services/employee.service';

@Component({
  selector: 'app-employee-record',
  templateUrl: './employee-record.component.html',
  styleUrls: ['./employee-record.component.css']
})

export class EmployeeRecordComponent implements OnInit {
//  userData: UserInfo;
  employeeData = Array<Employee>();
  managerList = Array<Employee>();
  usersData = Array<UserList>();
  filterBy: string = "All";
  closeResult: string;
  updateEmployee:Employee;
  employeeName = "";
  createMenu = true;
  mode = "Update";

  constructor(public userInfo: UserInfoService,
    public idStorage: IdStorageService,
    public router: Router,
    public employeeService : EmployeeService, public modalService: NgbModal) { }

  ngOnInit(): void {
    this.createMenu = true;
    this.getUserData();
    this.getEmployees();
    this.getManagerList();
    }

  clickHandlerEmployee(event) {
    var userid : string

    for (let i = 0; i < this.usersData.length; i++) {
      //console.log(userArray[i].userID);
      if (this.usersData[i].userName == event.target.textContent){
       userid = this.usersData[i].userID;
      }
    }

    this.updateEmployee.UserId = userid;
  }

  clickHandlerManager(event) {
    var userid : string

    for (let i = 0; i < this.managerList.length; i++) {
      //console.log(userArray[i].userID);
      if (this.managerList[i].employeeName == event.target.textContent){
       userid = this.managerList[i].employeeName;
      }
    }

    this.updateEmployee.managerName = userid;
  }

  generateMenu(filter:string): void {
    //htmlelent
    var tempArray : any;
    if (filter=='manager') tempArray = this.managerList;
    if (filter=='employee') tempArray = this.usersData;

    if (this.createMenu == true){
      this.createMenu = false;
      if (filter=='manager') var template = document.getElementById('template-manager');
      if (filter=='employee') var template = document.getElementById('template-employee');
      // for(let o = 1;o<template.childElementCount;o++){
      //   template.
      //   template.removeChild
      // }
    //console.log(template);
    for(let i=0;i<tempArray.length ;i++){
      var clone = template.lastElementChild.cloneNode(true);
      if (filter=='employee'){
        clone.textContent = this.usersData[i].userName;
        clone.addEventListener("click", this.clickHandlerEmployee.bind(this));
      }
      if (filter=='manager'){
        clone.textContent = this.managerList[i].employeeName;
        clone.addEventListener("click", this.clickHandlerManager.bind(this));
      }
      template.appendChild(clone);
    }
  }
  }

refreshEmployeeMenu(){
  this.createMenu = true;
  this.generateMenu('employee');
}

refreshManagerMenu(){
  this.createMenu = true;
  this.generateMenu('employee');
}

  // public changeFilterButton(event){
  //   this.changeFilter();
  // }
  // getArrayCount(temp : Array<any>) : number{
  //    var key, count = 0;
  //    for(key in temp) {
  //        count++;
  //    }
  //    return count;
  // }

  getEmployees(){
    var tempusers : Array<UserList>=[];
    this.usersData = [];
    this.userInfo.getUserList().subscribe((res) => {
      tempusers = res as unknown as Array<UserList>;
        for(let i = 0; i < tempusers.length; i++){
          if (tempusers[i].employee == true){
          this.usersData.push(tempusers[i]);
          }
    }
    this.createMenu = true;
    this.generateMenu('employee');
    });
  }

  public changeFilter(filter:string = "All"){
    this.filterBy = filter;
    this.getUserData(filter);
    this.getEmployees();
  }

  updateType(type:string){
    this.updateEmployee.employeeType = type;
  }

  updateManager(docid:string,employee : Employee){
    this.updateEmployee.managerName = employee.employeeName
    // employee.employeeManager = !employee.employeeManager;
    // this.employeeService.updateEmployeeRecord(docid,employee)
    // this.idStorage.setManager(employee.employeeManager);
  }
  isManager():boolean {
    return this.idStorage.getManager();
  }

  getUserData(filter : string = "") {
        this.employeeService.getByUserID("",filter).subscribe((res) => {
          this.employeeData = res as unknown as Array<Employee>;
        });
      }
  getManagerList() {
        this.employeeService.getManagerList().subscribe((res) => {
          this.managerList = res as unknown as Array<Employee>;
        });
      }
  open(content, id:string, item : Employee) {
        this.createMenu = true;
        this.employeeService.setEmployeeID(id);
        this.updateEmployee = item;
        this.modalService.open(content,
       {ariaLabelledBy: 'modal-basic-title'}).result.then(
         (result) => {
          this.closeResult = `Closed with: ${result}`;
          },
         (reason) => {
          this.closeResult =
             `Dismissed ${this.getDismissReason(reason)}`;

             //this.ngOnInit();
              this.getUserData(this.filterBy);
              this.getEmployees();
             //this.createMenu = true;
        });
      }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
    }

    closeUpdateBox(){
        this.modalService.dismissAll();
    }

    updateRecord(id: string, item : Employee){
        this.employeeService.updateEmployeeRecord(id,item)
        //window.location.reload();
        this.modalService.dismissAll();
    }
}


