import { Component, OnInit } from '@angular/core';
import {formatDate} from '@angular/common';
import { FeedbackService } from '../../services/feedback.service';
import { IdStorageService } from '../../services/id-storage.service';
import { Feedback } from '../../interfaces/feedback'
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-feedback-utility',
  templateUrl: './feedback-utility.component.html',
  styleUrls: ['./feedback-utility.component.css']
})
export class FeedbackUtilityComponent implements OnInit {

  feedbackList = Array<Feedback>();
  category : string = "All"

  closeResult : string;
  updateFeedback : Feedback;
  updateID : string;
  readonly = false;

  constructor(public idstorage : IdStorageService, public  feedBackService : FeedbackService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.getFeedbackData(this.category);
  }

  getFeedbackData(filter : String = "") {
    this.feedBackService.getFeedBack(filter).subscribe(
      res => {
        this.feedbackList = res as Array<Feedback>
  })
  }

  changeFilter(filter:string){
    this.category = filter;
    this.getFeedbackData(filter);
  }

  displayDate(date : Date) : string{
    return "" ;//date.toISOString();
    //formatDate(date,'yyyy-MM-dd',"en-US");

  }

  async updateList(id: string, field: string, event: any) {
    var editField : any;
    if (event.target.type == "checkbox"){
      editField = event.target.checked;
    } else {
      editField = event.target.textContent;
    }

      // console.log(event.target.type);
      //console.log(id);
      this.feedBackService.updateFeedbackItem(id, field, editField);
      await this.getFeedbackData(this.category);
      // if (this.userType=='All') {
      //   this.getAllUser();
      // } else {
      //   this.getFilteredUser();
      // }
      // this.personList[id][property] = editField;
    }

inputFilter() {
  var input, filter, table, tr, td, td1, td2, i, txtValue;
  input = document.getElementById("myInput");
  filter = input.value.toUpperCase();
  table = document.getElementById("table");
  tr = table.getElementsByTagName("tr");
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[1];
    td1 = tr[i].getElementsByTagName("td")[0];
    // td2 = tr[i].getElementsByTagName("td")[2];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
        continue
      } else {
        tr[i].style.display = "none";
      }

    }
    if (td1){
      txtValue = td1.textContent || td1.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
        continue
      } else {
        tr[i].style.display = "none";
      }
     }
    // if (td2){
    //   txtValue = td2.textContent || td2.innerText;
    //   if (txtValue.toUpperCase().indexOf(filter) > -1) {
    //     tr[i].style.display = "";
    //     continue
    //   } else {
    //     tr[i].style.display = "none";
    //   }

    // }
  }
}

editBox(content, feedback : Feedback){ //,ride:RideData){
  this.updateFeedback = feedback;
  this.modalService.open(content,
    {ariaLabelledBy: 'modal-basic-title'}).result.then(
      (result) => {
       this.closeResult = `Closed with: ${result}`;
       },
      (reason) => {
       this.closeResult =
          `Dismissed ${this.getDismissReason(reason)}`;
     });

}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

update(id: string){
    this.updateID = id;
    this.feedBackService.updateFeedback(id,this.updateFeedback)
}
}
