import { Component, OnInit } from '@angular/core';
import { Faq } from 'src/app/interfaces/faq';
import { FaqCategory } from 'src/app/interfaces/faq-category';
import { FaqServiceService } from 'src/app/services/faq-service.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {
  allGeneralData: Faq[];
  allUsageData:Faq[];
  allEarnData:Faq[];
  allRedeemData:Faq[];
  allcategory:FaqCategory[];
  generalid:string;
  usageid:string;
  earnid:string;
  redeemid:string;
  constructor( public faqService:FaqServiceService) { }

  ngOnInit(): void {
    this.getFAqcategories()
    this.getFAQDataAll()
  }

  getFAqcategories(){
    this.faqService.getFAQCategories().subscribe(
      res => {
        this.allcategory = res.sort((a,b) => (a as any).category - (b as any).category)
       })
  }

  getFAQDataAll() {
    this.faqService.getFAQGeneral().subscribe(
      res => {
        this.allGeneralData = res.sort((a,b) => (a as any).sortOrder - (b as any).sortOrder)
       })
    this.faqService.getFAQUsage().subscribe(
      res => {
        this.allUsageData = res.sort((a,b) => (a as any).sortOrder - (b as any).sortOrder)
      })
    this.faqService.getFAQEarn().subscribe(
      res => {
        this.allEarnData = res.sort((a,b) => (a as any).sortOrder - (b as any).sortOrder)
      })
    this.faqService.getFAQRedeem().subscribe(
      res => {
        this.allRedeemData = res.sort((a,b) => (a as any).sortOrder - (b as any).sortOrder)
      })
  }
}
