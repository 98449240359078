import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Feedback } from '../interfaces/feedback'

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private db: AngularFirestore) { }

  getFeedBack(filter : String = ""): Observable<Feedback[]> {
    let ref = this.db.collection('feedback')
    switch (filter){
      case "Unreviewed":{
        ref = this.db.collection('feedback',reff => reff.where('topicImplemented', '==', false));
        ref = this.db.collection('feedback',reff => reff.where('topicReviewed', '==', false));
        break;
      }
      case "Reviewed":{
        ref = this.db.collection('feedback',reff => reff.where('topicImplemented', '==', false));
        ref = this.db.collection('feedback',reff => reff.where('topicReviewed', '==', true));
        break;
      }
      case "Implemented":{
        ref = this.db.collection('feedback',reff => reff.where('topicImplemented', '==', true));
       // ref = this.db.collection('feedback',reff => reff.where('topicSelected', '==', true));
        break;
      }
      case "Selected":{
        ref = this.db.collection('feedback',reff => reff.where('topicSelected', '==', true));
       // ref = this.db.collection('feedback',reff => reff.where('topicSelected', '==', true));
        break;
      }

    }

		return ref
			.snapshotChanges().pipe(
				map(action => {
					return action.map(res => {
						const feedback = res.payload.doc.data() as Feedback;
						const id = res.payload.doc.id;
						return {id, ...feedback };
					})
				})
			);
	}

  updateFeedbackItem(id:string = "",item:string, value:any){
    return this.db.collection('feedback').doc(id).update({
        [item]: value
		})
  }

  createFeedback(feedback : Feedback){
    return this.db.collection('feedback').add({...feedback});
  }

  updateFeedback(id:string, feedback : Feedback){
      return this.db.collection('feedback').doc(id).update({
        topicCategory: feedback.topicCategory,
        topicClient: feedback.topicClient,
        topicDate: feedback.topicDate,
        topicDetails: feedback.topicDetails,
        topicNotes: feedback.topicNotes,
        topicPositive: feedback.topicPositive,
        topicReviewed: feedback.topicReviewed,
        topicSelected: feedback.topicSelected,
        topicImplemented: feedback.topicImplemented,
        topicSubject: feedback.topicSubject,
        topicRating: feedback.topicRating
      });
  }
}
