import { Employee } from './../interfaces/employee';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { User } from "../interfaces/user";
import { AngularFirestore } from '@angular/fire/firestore';
import { UserInfoService } from './user-info.service';
import { from } from 'rxjs';
import { IdStorageService } from './id-storage.service';
import Swal, { SweetAlertIcon } from 'sweetalert2'
import { UserInfo } from '../interfaces/user-info';
import { AngularFireStorage } from '@angular/fire/storage'

@Injectable({
  providedIn: 'root'
})
export class UserAuthserviceService {
  user: User;
  imageURL: string
  employeeImgURL: string
  userData: UserInfo
  currentUser: any;
  accountErrorMessage: string = "";

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public idstorage: IdStorageService,
    public af: AngularFireStorage,
    public router: Router, public userInfo: UserInfoService) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.user = user;
        this.idstorage.setloggedIn("true")
        let uid = user.uid
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        this.idstorage.setloggedIn("false")
        this.idstorage.setUid("")
        localStorage.setItem('user', null);
      }
    })
  }

  // public getInstance() : AngularFirestore {
  //   return this.afs;
  // }

  async login(email: string, password: string) {
    if (email == "" || password == "") {
      this.showErrorAlert()
      return
    }
    this.accountErrorMessage = "";
    var result = await this.afAuth.signInWithEmailAndPassword(email, password)
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-email": {
            this.accountErrorMessage = "Wrong email address.";
            break;
          }
          case "auth/wrong-password": {
            this.accountErrorMessage = "Wrong password.";
            break;
          }
          case "auth/user-not-found":
            {
              this.accountErrorMessage = "Wrong email address or password.";
              break;
            }
          default:
            {
              this.accountErrorMessage = "Unexpected Error";
              break;
            }
        }
      });
    if (this.accountErrorMessage != "") {
      this.showErrorAlert(this.accountErrorMessage)
      return
    }
    this.idstorage.setloggedIn("true")
    this.toastMessage('Signed in successfully.')
    this.reload_profile()
    this.reload_profile_employee()
  }

  async new_register(email: string, password: string, user: UserInfo) {
    var result = await this.afAuth.createUserWithEmailAndPassword(email, password)
    var result2 = await this.afAuth.signInWithEmailAndPassword(email, password)
    let uid = (await this.afAuth.currentUser).uid
    this.idstorage.setUid(uid)
    this.idstorage.setloggedIn("true")
    this.sendEmailVerification()
    return from(this.afs.collection('userInfo').doc(uid).set({
      admin: false,
      agreement: user.agreement,
      city: user.city,
      companyName: user.companyName,
      driver: user.driver,
      email: user.email,
      employee: false,
      firstName: user.firstName,
      investor: false,
      lastName: user.lastName,
      ofAge: user.ofAge,
      primaryPhone: user.primaryPhone,
      secondaryPhone: user.secondaryPhone,
      usageOption: user.usageOption,
      adControl: user.adControl,
      created: user.created,
    }))
  }

  async sendEmailVerification() {
    await (await this.afAuth.currentUser).sendEmailVerification()
    this.router.navigate(['profile-info']);
  }

  async sendPasswordResetEmail(passwordResetEmail: string) {
    return await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  async logout() {
    await this.afAuth.signOut();
    this.toastMessage('Logged Out successfully.')
    localStorage.removeItem('user');
    this.router.navigate(['/home']);
    this.idstorage.setloggedIn("false")
    this.idstorage.setUid("")
    this.idstorage.setEmployeeID("");
    sessionStorage.clear()
  }

  getUserData() {
    this.userInfo.getUserByID(this.idstorage.getUId()).subscribe((res) => {
      this.userData = res as unknown as UserInfo;
    })
  }



  toastMessage(msg: string, icon: SweetAlertIcon = 'success'): void {
    Swal.fire({
      icon: icon,
      title: msg,
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      confirmButtonColor: "#FF66C4",
      timer: 3000,
      timerProgressBar: false,

      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
  }

  showErrorAlert(msg: string = 'Email/Password was invalid') {
    Swal.fire({
      icon: 'error',
      title: msg,
      showConfirmButton: true,
      confirmButtonColor: "#FF66C4",
    })
  }

  async getProfileImageURL(): Promise<string> {
    await this.af.storage.ref('/profile/' + this.idstorage.getUId())
      .getDownloadURL().then(url => { this.imageURL = url; });
    return this.imageURL
  }
  async getEmployeeImg() :Promise<string> {
    await this.af.storage.ref('/Employee/' + this.idstorage.getUId())
      .getDownloadURL().then(url => { this.employeeImgURL = url; });
    return this.employeeImgURL
  }

  async reload_profile_employee() {
    let uid = (await this.afAuth.currentUser).uid
    this.idstorage.setUid(uid)
    this.idstorage.setEmployeeImg(await this.getEmployeeImg())
    this.getUserData()
    this.router.navigate(['profile-info']);
  }

  async reload_profile() {
    let uid = (await this.afAuth.currentUser).uid
    this.idstorage.setUid(uid)
    this.idstorage.setProfileImage(await this.getProfileImageURL())
    this.getUserData()
    this.router.navigate(['profile-info']);
  }

}
