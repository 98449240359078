import { Component, OnInit } from '@angular/core';
import { Employee } from '../interfaces/employee';
import { EmployeeService } from '../services/employee.service';
import { AngularFireStorage } from '@angular/fire/storage'

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
   allTeamMembers: Array<Employee>  = Array<Employee>();
   imageURLs: Array<string> = Array<string>();

  constructor( public employeeService:EmployeeService, private fbStorage: AngularFireStorage) { }

  ngOnInit(): void {
    this.getTeamMembers()
  }

  async getTeamMembers() {
    this.employeeService.getEmployeeWithDisplayStatus().subscribe(
      res => {
        this.allTeamMembers = res.sort((a,b) => (a as any).employeeNumber - (b as any).employeeNumber)

        this.imageURLs = new Array<string>()

        this.allTeamMembers.forEach(item => {
          this.fbStorage.storage.ref('/Employee/' + item.UserId)
          .getDownloadURL().then(url => {
            this.imageURLs.push(url);
         });
        });

       })
  }
  getImage(image : string) : string {
    var index : number = 0;

    for (index = 0;index < this.imageURLs.length;index++) {
      var find = this.imageURLs[index].includes(image);
      //var found=this.imageURLs.indexOf(image);
      if (find) {
        return this.imageURLs[index];
      }
    }
    return "/assets/Images/VARA.png"
  }
}
