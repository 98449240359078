const template = '<div class="form-check drop-height">' +
'<p>Please give us your name</p><input id="swal-input1" class="swal2-input">' +
'<p>Enter your email address</p><input id="swal-input2" class="swal2-input">' +
'<p>Are you interested in ...</p>' +
'<select class="dropdown-pink button-height txt-apperance drop-height" id="swal-input3">'+
  '<option>Android</option>' +
  '<option>iOS </option>' +
  '<option>iOS & Android</option>' +
'</select><br><br>' +
'<input class="form-check-input " type="checkbox" value="" id="checkbox2">' +
'<label class="form-check-label" for="checkbox2">Agree to be added to our mailing list</label>' +
'</div>';

export default template;
