import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmailList } from '../interfaces/emailList';

@Injectable({
	providedIn: 'root'
})
export class MailingListService {

	constructor(private db: AngularFirestore
		//private idStore: IdStorageService
    ) { }

    getInstance(){
      return this;
    }

    async remove(person : EmailList){
      let ref = await this.removeFromEmailList(person);
    }

    async removeFromEmailList(person : EmailList) {
      this.db.collection('emailList').doc(person.id).delete();
    }

	getMailList(typed : String = "", filter : String = ""): Observable<EmailList[]> {
    let ref = this.db.collection('emailList')
    switch (filter){
      case "iOS":{
        ref = this.db.collection('emailList',reff => reff.where('ios', '==', true));
        ref = this.db.collection('emailList',reff => reff.where('android', '==', false));
        break;
      }
      case "Android" :{
        ref = this.db.collection('emailList',reff => reff.where('android', '==', true))
        ref = this.db.collection('emailList',reff => reff.where('ios', '==', false));
        break;
      }
    }

		return ref
			.snapshotChanges().pipe(
				map(action => {
					return action.map(res => {
						const email = res.payload.doc.data() as EmailList;
						const id = res.payload.doc.id;
            email.id = id;
						return { id, ...email };
					})
				})
			);
	}

  addToEmailList(emailList: EmailList) : void {
    console.log('saving ' + emailList.toString());
     Promise.resolve(this.db.collection('emailList').doc().set({
       android : emailList.android,
       clientName : emailList.clientName,
       date : emailList.date,
       emailAddress : emailList.emailAddress,
       emailOK : emailList.emailOK,
       ios : emailList.ios,
    }));
  }

}
