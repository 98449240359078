
import { LogicalProjectPath } from '@angular/compiler-cli/src/ngtsc/file_system';
import { Component, OnInit } from '@angular/core';
import { UserInfo } from 'src/app/interfaces/user-info';
import { UserInfoService } from 'src/app/services/user-info.service';

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.css']
})
export class UserAccessComponent implements OnInit {
  allUserData: UserInfo[];
  editField: string;
  userType : string = "All";

  constructor( public userInfoService:UserInfoService) { }

  ngOnInit(): void {
    this.getAllUser();
  }

 getAllUser() {
    this.userInfoService.getAllUserInfo().subscribe(
      res => {
        this.allUserData = res as Array<UserInfo>
    })
  }

  getFilteredUser() {
    this.userInfoService.getFilteredUsers(this.userType).subscribe(
      res => {
        this.allUserData = res as Array<UserInfo>
    })
  }



  updateList(id: string, field: string, event: any) {
  //if (event.target.textContent == 'true') var editField : any = true
  //if (event.target.textContent == 'false') var editField : any = false
  //if (event.target.textContent != 'true' || event.target.textContent != 'false')
  //const editField = event.target.textContent;
  var editField : any;
  if (event.target.type == "checkbox"){
    editField = event.target.checked;
  } else {
    editField = event.target.textContent;
  }
    //console.log(editField);
    //console.log(id);
    this.userInfoService.updateUser(id,field,editField)
    if (this.userType=='All') {
      this.getAllUser();
    } else {
      this.getFilteredUser();
    }
    // this.personList[id][property] = editField;
  }

  changeValue(id: string, field: string, event: any) {
    this.editField = event.target.textContent;
  }

  changeUserType(value : string){
    this.userType = value;
    this.getFilteredUser();
  }

inputFilter() {
  var input, filter, table, tr, td, td1, td2, i, txtValue;
  input = document.getElementById("myInput");
  filter = input.value.toUpperCase();
  table = document.getElementById("table");
  tr = table.getElementsByTagName("tr");
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[0];
    td1 = tr[i].getElementsByTagName("td")[1];
    td2 = tr[i].getElementsByTagName("td")[2];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
        continue
      } else {
        tr[i].style.display = "none";
      }

    }
    if (td1){
      txtValue = td1.textContent || td1.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
        continue
      } else {
        tr[i].style.display = "none";
      }

    }
    if (td2){
      txtValue = td2.textContent || td2.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
        continue
      } else {
        tr[i].style.display = "none";
      }

    }
  }

}
}
