import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfo } from '../interfaces/user-info';
import { FaqServiceService } from '../services/faq-service.service';
import { IdStorageService } from '../services/id-storage.service';
import { UserAuthserviceService } from '../services/user-authservice.service';
import { UserInfoService } from '../services/user-info.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { timeStamp } from 'node:console';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  isLoggedIn: string
  userData: UserInfo

  constructor(public idstorage: IdStorageService,
    private af:AngularFireStorage,
    public userInfo: UserInfoService,
    public idStorage: IdStorageService,
    public userauthService: UserAuthserviceService, public router: Router, 
    public faqService:FaqServiceService)
   { }

  ngOnInit(): void {
    if (this.idstorage.getUloggedIn() == "true") {
      this.userauthService.reload_profile()
      this.isAdmin()
      this.isInvestor()
    }
    this.faqService.getCategoriesid()
  }

  isInvestor(): boolean {
    if ( this.idstorage.getInvestStatus()== "true") {
      return true
    }else{
    return false
    }
  }

  isAdmin(): boolean {
    if ( this.idstorage.getAdminStatus()== "true") {
      return true
    }else{
    return false
    }
  }

  reload(){
    setTimeout(function(){
    window.location.reload();
    },1000);
  }
}
