import { Component, OnInit, TemplateRef } from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})

export class PopupComponent implements OnInit {

  saleSupport = [                                           // Tab 1
    { title: 'Sales Support', fragment: 'salesupport'},         // Dropdown text heading
    { title: 'Earning', fragment: 'earning' },               // Dropdown menu items
    { title: 'Redeeming', fragment: 'redeeming' }
    // { title: 'Invest', fragment: 'invest' }
  ];
  userSupport = [                                           // Tab 2
    { title: 'User Support', fragment: 'usersupport'},          // Dropdown text heading
    { title: 'Website Issues', fragment: 'siteissues'},       // Dropdown menu items
    { title: 'App Issues', fragment: 'appissues' },
    { title: 'Commercial', fragment: 'commercial' },
    { title: 'Investing', fragment: 'invest' }
  ];
  otherSupport = [                                          // Tab 3
    { title: 'Im interested in...', fragment: 'interest'},  // Dropdown text heading
    { title: 'Personal Use', fragment: 'personal' },        // Dropdown menu items
    { title: 'Commercial Use', fragment: 'commercial' },
    { title: 'Investing', fragment: 'invest' }
  ];

  closeResult = '';
  name : string = '';
  account_type_str : string = 'Im interested in...';
  email : string = '';
  primaryPhone : string = '';
  message : string = '';
  @ViewChild('content') public contactContent: TemplateRef<any>;

  constructor(private modalService: NgbModal, private router : Router) { }

  ngOnInit(): void {
    init("user_2QDWnbR0VGeBJsAsc02i7");
    this.changeInterest("sales");
  }

  changeInterest(tab : string){
    switch(tab){
      case "sales" :{
        this.account_type_str = this.saleSupport[0].title;
       break;
      }
      case "user" :{
        this.account_type_str = this.userSupport[0].title;
        break;
       }
      case "other" :{
        this.account_type_str = this.otherSupport[0].title;
        break;
       }
    }
  }

  switchLink(type : string){
    switch (type){
      case "invest":{
        this.account_type_str = "Investing";
        //alert("Investing?!?!?!?!?");
        break;
      }
      case "appissues":{
        this.account_type_str = "App Issues";
        break;
      }
      case "commercial":{
        this.account_type_str = "Commercial Use";
        break;
      }
      case "earning" :{
        this.account_type_str = "Earning";
        break;
      }
      case "redeeming" :{
        this.account_type_str = "Redeeming";
        break;
      }
      case "usersupport" :{
        this.account_type_str = "User Support";
        break;
      }
      case "salesupport" :{
        this.account_type_str = "Sales Support";
        break;
      }
      case "siteissues" :{
        this.account_type_str = "WebSite Issues";
        break;
      }
      case "interest" :{
        this.account_type_str = 'Im interested in...';
        break;
      }
      case "personal" :{
        this.account_type_str = 'Personal Use';
        break;
      }
       default:
        this.account_type_str  = 'Im interested in...';
      //   alert("Must select interest please and thank you.")
    }
  }

  async getFields(){
    this.message = (<HTMLInputElement>document.getElementById("message")).value;
    if (this.name == "" && this.email == "") {
      this.msgBox("Must enter a name & and email address", 'warning');
     return
    }

    var { value: bool } = await Swal.fire ({
      title: 'You Entered the following information',
      //background: "#000",
      html: '<p>Name - ' + this.name + '</p>'+
            '<p>Interest - ' + this.account_type_str + '</p>' +
            '<p>Phone # - ' + this.primaryPhone + '</p>' +
            '<p>Message - ' + this.message + '</p>',
      footer: 'Is this Information Correct?',
      showCancelButton : true,
      focusConfirm: false,
      confirmButtonColor: "#FF66C4",
    })

    if (bool) {
      this.msgBox('Message sent.');
      this.sendMessage();
    } else {

    }
    this.modalService.dismissAll();
  }

  msgBox(msg : string , icon : any = 'success'){
   Swal.fire ({
     icon: icon,
      title: msg,
      focusConfirm: false,
      confirmButtonColor: "#FF66C4",
    })
  }
  open(content: TemplateRef<any>) {
    this.modalService.open(content,
   {ariaLabelledBy: 'modal-basic-title', size : 'lg'}).result.then(
     (result) => {
      this.closeResult = `Closed with: ${result}`;
      },
     (reason) => {
      this.closeResult =
         `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  sendMessage(){
    var templateParams = {
      from_name: this.name,
      message: this.message,
      from_email: this.email,
      interest: this.account_type_str
  };

  emailjs.send('service_phtm1m5', 'template_mfylvq8', templateParams)
      .then(function(response) {
         console.log('SUCCESS!', response.status, response.text);
      }, function(error) {
         console.log('FAILED...', error);
      });

  }
}
