import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { EmailComponent } from '../contact/email/email.component';
import { MailingListService } from '../services/mailing-list.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public Email : EmailComponent, public MailList : MailingListService) {
  }

  ngOnInit(): void {
    this.Email = new EmailComponent(this.MailList);
  }


}
