import { Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { HomeComponent } from 'src/app/home/home.component';
import { UserAuthserviceService } from 'src/app/services/user-authservice.service';
import Swal from 'sweetalert2';
import { EmailList } from '../../interfaces/emailList';
import { MailingListService } from '../../services/mailing-list.service';
import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { GetHTMLService } from '../../services/get-html.service'
import { ExportEmailService } from '../../services/export-email.service';
import  template from '../../template';


@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css'],
})
export class EmailComponent implements OnInit {

  div : HTMLElement;
  html_string : string;
  file_path = 'email.component.html';
  @ViewChild('email-contact',{static: false})  myAlert: ElementRef;
  http : HttpClient
  test : GetHTMLService
  exportList = new ExportEmailService();

  constructor(public  EmailService : MailingListService) {
    this.http = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
    this.test = new GetHTMLService(this.http)
  }

  ngOnInit(): void {
    //this.div= document.getElementById("email-contact");0
  }

  listExport(doc : Document){
    let table = doc.getElementById("myTable");// as unknown as ElementRef<HTMLElement>;
   // alert(table.id);
    //alert(table.innerText);
    this.exportList.exportTableElmToExcel(table, table.id)
  }

  async AddToList(){
   /// this.test.alertmsg()
    //alert(window.location.href)
      // this.http.get(window.origin + '/assets/email-contact/contact.html').subscribe(data => {

      //   alert(data);
      // })
    // this.http
    // .get('/#/' + this.file_path,
    //      { responseType: 'text' })
    // .subscribe(data => {
    //     this.html_string = data;
    //     alert(this.html_string);
    // });
    //alert(this.myAlert)
    //alert(this.div)
   // return
    //alert(this.myAlert.nativeElement)

    const { value: formValues } = await Swal.fire({
      title: 'Join Our Mailing List',
      html: template,//'<OBJECT data="./assets/email-contact/contact.html"></OBJECT>',
        // '<div class="form-check drop-height">' +
        // '<p>Please give us your name</p><input id="swal-input1" class="swal2-input">' +
        // '<p>Enter your email address</p><input id="swal-input2" class="swal2-input">' +
        // '<p>Are you interested in ...</p>' +
        // '<select class="dropdown-pink button-height txt-apperance drop-height" id="swal-input3">'+
        //   '<option>Android</option>' +
        //   '<option>iOS </option>' +
        //   '<option>iOS & Android</option>' +
        // '</select><br><br>' +
        // '<input class="form-check-input " type="checkbox" value="" id="checkbox2">' +
        // '<label class="form-check-label" for="checkbox2">Agree to be added to our mailing list</label>' +
        // '</div>',
        confirmButtonColor: "#FF66C4",
       focusConfirm: false,
      preConfirm: () => {
        return [
          (<HTMLInputElement>document.getElementById('swal-input1')).value,
          (<HTMLInputElement>document.getElementById('swal-input2')).value,
          (<HTMLInputElement>document.getElementById('swal-input3')).value,
          (<HTMLInputElement>document.getElementById('checkbox2')).checked
        ]
      }
  })

    if (formValues) {
      var android = false;
      var ios = false;
      switch(formValues[2]){
        case "Android":
          android = true;
          ios = false;
        break;
        case "iOS":
          android = false;
          ios = true;
        break;
        case "iOS & Android":
          android = true;
          ios = true;
        break;
      }
      if (formValues[3] as boolean == false){
        Swal.fire('You must accept to be added to our list');
        return
      }
      if (formValues[0] == "" || formValues[1] == ""){
          Swal.fire('Please provide a Name and/or Email Address');
          return
      }
      var emailList = new EmailList(new Date(),"", formValues[0].toString(), formValues[1].toString(), formValues[3] as boolean,android, ios);
      Swal.fire({
        title: 'Confirm the following details:',
        html: emailList.date.toString() + '<br>' +
        emailList.clientName + '<br>' +
        emailList.emailAddress + '<br>' +
        emailList.emailOK.toString() + ' - Accepted<br>' +
        emailList.android.toString() + ' - Android<br>' +
        emailList.ios.toString() + ' - IOS<br>',
        confirmButtonColor: "#FF66C4"
      })

      await this.EmailService.addToEmailList(emailList);
    }
  }
}
