import { Component, OnInit } from '@angular/core';
import { MailingListService } from '../services/mailing-list.service';
import { EmailComponent } from '../contact/email/email.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  constructor(public Email : EmailComponent, public MailList : MailingListService) {
  }

  ngOnInit(): void {
    this.Email = new EmailComponent(this.MailList);
  }
}


