import { Component, OnInit } from '@angular/core';
//import { UserInfo } from 'node:os';
import { CommandName } from 'protractor';
import { UserAuthserviceService } from '../services/user-authservice.service';
import { UserInfoService } from '../services/user-info.service';
import { UserInfo } from '../interfaces/user-info'
// import { TermsComponent } from '../footer/terms/terms.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css', './sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  user: UserInfo;//= new UserInfo();
  selected: boolean = false;
  account_type_bool : boolean = false;
  account_type_str : string = "Commercial"

  ofAge: boolean = false
  email : string = ""
  password : string =""
  firstName : string = ""
  lastName : string = ""
  city : string = ""
  primaryPhone : string = ""
  secondaryPhone : string = ""
  employee : boolean = false
  driver: boolean = false
  agreement: boolean = false
  CompanyName: string = ""
  usageOption: string = "Commercial"
  passcode : string = "0000"
  adControl: string = ""
  created: Date
  shortName: string

  constructor(public authService: UserAuthserviceService,public userInfoService: UserInfoService) { }

  ngOnInit(): void {
  }

mustAgreeTerms(){
 Swal.fire({
    position: 'center',
    title: 'You must agree to the Terms and Conditions in order to continue',
    allowOutsideClick: false,
    confirmButtonColor: "#FF66C4"
  })
}
 async agreePopup(){
   if (this.agreement) return
   if (this.passcode.length < 4 && this.passcode.length < 6){
    Swal.fire({
      position: 'center',
      title: 'Passcode must be between 4 and 6 characters long.',
      allowOutsideClick: false,
      confirmButtonColor: "#FF66C4"
    })
    return
   }
  const { value: accept } = await Swal.fire({
    position: 'center',
    title: 'Terms and conditions',
    allowOutsideClick: false,
    confirmButtonColor: "#FF66C4",
    showCancelButton : true,
    //buttonsStyling : false,
    html: '<div class="background-clr border-pink scroll-agree-wrapper">' +
    '<p class= "txt-clr-lgray term-text-wide">' +
    ' At VARA Platforms , we are committed to providing our customers with the' +
    ' best experience possible.  We are letting you know about recent updates to our Terms of Service.</p>' +
    '<p></p>' +
    '<p class= "txt-clr-lgray term-text-wide">' +
    'Please read these terms of service ("disclaimer") carefully before using [website] website (“website”, "service")' +
    'operated by[name] ("us", "we", "our"). The content displayed on the website is the intellectual property of the [name].' +
    'You may not reuse, republish, or reprint such content without our written consent.' +
    'All information posted is merely for educational and informational purposes.' +
    'It is not intended as a substitute for professional advice.</p>' +
  '<p></p>' +
  '<p class= "txt-clr-lgray term-text-wide">' +
    'Should you decide to act upon any information on this website, you do so at your own risk. While the information on this' +
    'website has been verified to the best of our abilities, we cannot guarantee that there are no mistakes or errors.' +
    'We reserve the right to change this policy at any given time, of which you will be promptly updated.' +
    'If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.</p>',
    input: 'checkbox',
    //background: "#FF66C4",
    inputValue: 0,
    inputPlaceholder:
      'I agree with the terms and conditions',
    confirmButtonText:
      'Continue&nbsp;<i class="fa fa-arrow-right"></i>',
    inputValidator: (result) => {
      return !result && 'You need to agree with Terms & Conditions'
    }
  })
  this.agreement = accept;
  if (accept) {
    Swal.fire({
      title: 'Thank you :)',
      confirmButtonColor: "#FF66C4"
    })
  }


  }

  switchPersonal():void{
    this.account_type_bool = false
    this.account_type_str = "Personal"
    this.usageOption = "Personal"
  }

  switchCommercial():void{
    this.account_type_bool = true
    this.account_type_str = "Commercial"
    this.usageOption = "Commercial"
  }

  switchDriver():void{
    this.account_type_bool = false
    this.account_type_str = "Driver"
    this.driver = true
  }

  switchOfAge():void{
    this.account_type_bool = false
    this.account_type_str = "ofAge"
    this.ofAge = true
  }

  checkAgreement():void{
    this.account_type_bool = false
    this.account_type_str = "agreement"
    this.agreement = true
  }

  register(){
    let user = new UserInfo(
      false, // admin
      this.agreement,
      this.city,
      this.CompanyName,
      this.driver,
      this.email,
      this.employee, // employees
      this.firstName,
      false, // investor
      this.lastName,
      this.ofAge,
      this.primaryPhone,
      this.secondaryPhone,
      this.usageOption,
      this.adControl,
      this.created = new Date(),
      this.shortName,
      this.passcode)

      this.authService.new_register(this.email, this.password, user)
  }

   // https://jsfiddle.net/DRSDavidSoft/zb4ft1qq/2/
   maxLengthCheck(object ,maxLen : number)
   {
     if (object.value.trim().length > maxLen)
       object.value = object.value.slice(0, maxLen)
   }

   isNumeric (evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode (key);
    var regex = /[0-9]|\./;
    if ( !regex.test(key) ) {
      theEvent.returnValue = false;
      if(theEvent.preventDefault) theEvent.preventDefault();
    }
  }
}
