import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IdStorageService {

  constructor() { }
  setUid(Uid: string) {
    sessionStorage.setItem('uid', Uid);
  }

  getUId(): string {
    return sessionStorage.getItem('uid');
  }


  setEmployeeID(id:string){
    sessionStorage.setItem('employeeID', id);
  }
  getEmployeeID(): string {
    return sessionStorage.getItem('employeeID');
  }

  setManager(manager:boolean){
    sessionStorage.setItem('isManager', manager.toString());
  }

  getManager():boolean {
    var manager = sessionStorage.getItem('isManager');
    if (manager == "true") return true
    return false;

  }

  setloggedIn(isloggedin: string) {
    sessionStorage.setItem('isloggedin', isloggedin);
  }

  getUloggedIn(): string {
    return sessionStorage.getItem('isloggedin')
  }

  setAdminStatus(isAdmin: string) {
    sessionStorage.setItem('isAdmin', isAdmin)
  }

  getAdminStatus(): string {
    return sessionStorage.getItem('isAdmin')
  }

  isAdmin() : boolean {
    if (sessionStorage.getItem('isAdmin') == "isAdmin") return true;
    return false;
  }

  setInvestStatus(isInvest: string) {
    sessionStorage.setItem('isInvest', isInvest)
  }

  getInvestStatus(): string {
    return sessionStorage.getItem('isInvest')
  }

  setProfileImage(profileImg: string) {
    sessionStorage.setItem('profileImg', profileImg)
  }

  getProfileImg(): string {
    return sessionStorage.getItem('profileImg')
  }

  setEmployeeImg(employeeImg: string) {
    sessionStorage.setItem('employeeImg', employeeImg)
  }
  getEmployeeImg() :string{
    return sessionStorage.getItem('employeeImg')
  }

  setGeneral(Gid: string) {
    sessionStorage.setItem('Gid', Gid)
  }

  getGeneral(): string {
    return sessionStorage.getItem('Gid')
  }

  setUsage(Usid: string) {
    sessionStorage.setItem('Usid', Usid)
  }

  getUsage(): string {
    return sessionStorage.getItem('Usid')
  }
  setEarn(Earnid: string) {
    sessionStorage.setItem('Earnid', Earnid)
  }

  getEarn(): string {
    return sessionStorage.getItem('Earnid')
  }

  setRedeem(Rid: string) {
    sessionStorage.setItem('Rid', Rid)
  }

  getRedeem(): string {
    return sessionStorage.getItem('Rid')
  }
}

