export class UserInfo {
    admin: boolean;
    agreement: boolean;
    city: string;
    companyName: string;
    driver: boolean;
    email: string;
    employee: boolean;
    firstName: string;
    investor: boolean;
    lastName: string;
    ofAge: boolean;
    primaryPhone: string;
    secondaryPhone: string;
    usageOption: string;
    adControl: string;
    created: Date;
    shortName: string;
    passcode : string;
    constructor(admin: boolean, agreement: boolean, city: string, companyName: string, driver: boolean, email: string, employee: boolean,
        firstName: string, investor: boolean, lastName: string, ofAge: boolean, primaryPhone: string, secondaryPhone: string,
        usageOption: string, adControl: string, created: Date, shortName: string, passcode : string) {
        this.admin = admin;
        this.agreement = agreement
        this.city = city;
        this.companyName = companyName;
        this.driver = driver;
        this.email = email;
        this.employee = employee;
        this.firstName = firstName;
        this.investor = investor;
        this.lastName = lastName;
        this.ofAge = ofAge;
        this.primaryPhone = primaryPhone;
        this.secondaryPhone = secondaryPhone;
        this.usageOption = usageOption;
        this.adControl = adControl;
        this.created = created;
        this.shortName = shortName
        this.passcode = passcode;
    }
}
